<template>
<div>
    <v-card class="display-block" flat>
        <h3>Modify Staff</h3>
        <v-card-text>
            <alert-message v-if="msg" :error="msg"></alert-message>
            <v-form ref="form" lazy-validation>
                <v-row>
                    <v-flex>
                        <v-text-field label="FirstName*" class="mx-3" :rules="firstNameRules" v-model="staff.firstName" required></v-text-field>
                    </v-flex>
                    <v-flex>
                        <v-text-field label="MiddleName" class="mx-3" v-model="staff.middleName"></v-text-field>
                    </v-flex>
                    <v-flex>
                        <v-text-field label="LastName*" class="mx-3" :rules="lastNameRules" v-model="staff.lastName" required></v-text-field>
                    </v-flex>
                </v-row>
                <v-flex>
                    <v-text-field label="Mobile No*" :rules="mobileNoRules" v-model="staff.mobileNo" required></v-text-field>
                </v-flex>
                <v-flex>
                    <v-text-field label="E-Mail" :rules="emailRules" v-model="staff.email"></v-text-field>
                </v-flex>
                <v-flex>
                    <v-autocomplete v-model="staff.roles" :items="rolesArray" label="Roles" item-text="name" item-value="_id" multiple return-object />
                </v-flex>
                <alert-message v-if="msg" :error="msg"></alert-message>
                <v-flex text-right>
                    <v-btn class="mr-4" :style="theme" @click="submit()">Save</v-btn>
                    <v-btn class="mr-4" :style="themeInverted" outlined @click="$router.go(-1)">Cancel</v-btn>
                </v-flex>
            </v-form>
        </v-card-text>
    </v-card>
</div>
</template>

<script>
import appConstants from '@/utils/appConstants'
export default {
    data() {
        return {
            staff: {
                firstName: "",
                middleName: "",
                lastName: "",
                mobileNo: "",
                email: "",
                roles: [],
                userType: 'STAFF',
            },
            items: [],
            rolesArray: []
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id
            this.staff.vendorId = this.getUserProfile().vendorId
            let roleData= await this.getItem(appConstants.ROLES_API + "?fields=name,_id,tag")
            this.rolesArray =roleData.data 
            if (this.id != 0) {
                this.staff = await this.getItem(appConstants.STAFFS_API + "/" + this.id)
            }
        },
        submit() {
            if (this.$refs.form.validate()) {
                if (this.id == 0) {
                    this.saveItem()
                } else {
                    this.updateItem()
                }
            }
        },
        async saveItem() {
            try {
                this.staff = await this.postItem(appConstants.STAFFS_API, this.staff)
                this.$router.push({ name: "Staffs" })
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data
            }
        },
        async updateItem() {
            try {
                await this.putItem(appConstants.STAFFS_API + "/" + this.staff._id, this.staff)
                this.$router.push({ name: "Staffs" })
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data
            }
        }
    },
}
</script>
